import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={512}
    height={512}
    x={0}
    y={0}
    viewBox="0 0 64 64"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <path
        d="M27.4 28.5c2.9 1.5 6.3 1.5 9.1 0l15.7-8 3.6-1.8c-1.3-2.8-4.2-4.8-7.6-4.8H15.8c-3.3 0-6.2 2-7.6 4.8l3.6 1.8z"
        opacity={1}
        className=""
      />
      <path
        d="M38.4 32.1c-2 1-4.2 1.5-6.4 1.5s-4.4-.5-6.4-1.5l-14.3-7.3-4-2v19c0 4.6 3.8 8.4 8.4 8.4h32.5c4.6 0 8.4-3.8 8.4-8.4v-19l-4 2z"
        opacity={1}
        className=""
      />
    </g>
  </svg>
);
export default SVGComponent;
