import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={512}
    height={512}
    x={0}
    y={0}
    viewBox="0 0 100 100"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <path
        d="M35.913 90c2.845 0 3.621-1.122 3.621-2.504 0-1.237-.044-4.513-.066-8.854-14.741 3.14-17.85-6.986-17.85-6.986-2.411-6.01-5.896-7.618-5.896-7.618-4.8-3.228.371-3.162.371-3.162 5.322.362 8.117 5.366 8.117 5.366 4.725 7.962 12.405 5.662 15.435 4.332.477-3.37 1.842-5.662 3.356-6.964-11.769-1.303-24.139-5.781-24.139-25.733 0-5.684 2.054-10.329 5.454-13.973-.596-1.316-2.385-6.611.464-13.783 0 0 4.438-1.396 14.573 5.339 4.24-1.157 8.744-1.731 13.249-1.758 4.505.026 9.009.601 13.249 1.758 10.069-6.735 14.507-5.339 14.507-5.339 2.848 7.172 1.06 12.467.53 13.783 3.378 3.643 5.432 8.289 5.432 13.973 0 20.005-12.387 24.408-24.179 25.689 1.855 1.563 3.577 4.756 3.577 9.636 0 6.969-.066 12.569-.066 14.26 0 1.365.59 2.473 3.643 2.473z"
        opacity={1}
        className=""
      />
    </g>
  </svg>
);
export default SVGComponent;
