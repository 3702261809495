import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={512}
    height={512}
    x={0}
    y={0}
    viewBox="0 0 100 100"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    xmlSpace="preserve"
    className=""
    {...props}
  >
    <g>
      <path
        d="M50 10c-22.1 0-40 17.9-40 40 0 8.8 2.8 16.9 7.6 23.5L12.1 88 28 83.4c6.3 4.2 13.9 6.6 22 6.6 22.1 0 40-17.9 40-40S72.1 10 50 10zm21.3 56.6L67 70.8c-4.5 4.5-16.4-.4-26.9-11-10.5-10.5-15.2-22.4-11-26.8l4.3-4.3c1.6-1.6 4.3-1.6 6 0l6.3 6.3c2.2 2.2 1.3 6-1.6 6.9-2 .7-3.4 2.9-2.7 4.9 1.1 4.7 7.2 10.5 11.7 11.6 2 .5 4.3-.7 4.9-2.7.9-2.9 4.7-3.8 6.9-1.6l6.3 6.3c1.6 1.7 1.6 4.4.1 6.2z"
        opacity={1}
        className=""
      />
    </g>
  </svg>
);
export default SVGComponent;
