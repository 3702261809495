import React, { useEffect, useRef } from "react";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Points from "./components/Points";
import Cta from "./components/Cta/Index";
import Serviecs from "./components/Serviecs";
import Banner from "./components/Banner";
import SmoothScroll from "./components/SmoothScroll";
import Aos from "aos";
import pageData from "./Data/Home.json";
import { Link } from "react-router-dom";
import MyPortfolio from "./components/MyPortfolio";
import Marquee from "./components/Marquee";
import SlidingTopBottom from "./components/Amination/FadeScreenAnimation/SlidingTopBottom";
import SlideUpAnimation from "./components/Amination/SlideUpAnimation";
import { Helmet } from "react-helmet";

const Home = () => {
  useEffect(() => {
    Aos.init({
      // Global settings for AOS
    });
  }, []); // Only run once when the component mounts

  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          Akash Singh | Professional Web Developer & Custom Web Solutions
        </title>
        <meta
          name="description"
          content="Akash Singh - A professional web developer specializing in custom websites, responsive design, and modern web development. Let's build your online presence with cutting-edge solutions."
        />
        <meta
          name="keywords"
          content="web developer, custom web development, responsive design, freelance web developer, modern website design, [City/Region]"
        />

        {/* Open Graph (OG) Tags */}
        <meta
          property="og:title"
          content="Akash Singh | Professional Web Developer & Custom Web Solutions"
        />
        <meta
          property="og:description"
          content="Akash Singh - A professional web developer specializing in custom websites, responsive design, and modern web development. Let's build your online presence with cutting-edge solutions."
        />
        <meta property="og:url" content="https://akashsingh.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image:alt"
          content="Akash Singh's Portfolio Thumbnail"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Akash Singh's Portfolio" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Akash Singh | Professional Web Developer & Custom Web Solutions"
        />
        <meta
          name="twitter:description"
          content="Akash Singh - A professional web developer specializing in custom websites, responsive design, and modern web development. Let's build your online presence with cutting-edge solutions."
        />
        <meta name="twitter:url" content="https://akashsingh.com" />
        <meta
          name="twitter:image"
          content="https://akashsingh.com/thumbnail.jpg"
        />
        <meta
          name="twitter:image:alt"
          content="Akash Singh's Portfolio Thumbnail"
        />
        <meta name="twitter:site" content="@akashsingh" />
        <meta name="twitter:creator" content="@akashsingh" />

        {/* Robots Meta Tags */}
        <meta name="robots" content="index, follow, max-image-preview:large" />
        <meta
          name="googlebot"
          content="index, follow, max-image-preview:large"
        />
      </Helmet>
      <Header />
      <SmoothScroll smoothness={2}>
        <div className="min-h-screen flex flex-col items-center bg-[#111111] overflow-visible p-0 relative">
          <Banner
            imageSrc={pageData.bannerData.imageSrc}
            title={pageData.bannerData.title}
            buttonText={pageData.bannerData.buttonText}
            buttonLink={pageData.bannerData.buttonLink}
            description={pageData.bannerData.description}
            aboutLink={pageData.bannerData.aboutLink}
            aboutText={pageData.bannerData.aboutText}
          />
          <Points data={pageData.points} />
          <Marquee Data={pageData.skills} />
          <MyPortfolio
            title={pageData.project.title}
            width={"xl:w-[90%] w-full flex-row "}
            parentClass={"  pt-10 relative gap-10 flex flex-col"}
            Data={pageData.project.projectData}
            LinkData={
              <Link to={pageData.project.pagelink}>
                <p
                  className="text-white text-sm font-semibold leading-tight text-center xl:block hidden uppercase tracking-[2px]"
                >
                  {pageData.project.title2}
                </p>
              </Link>
            }
          />
          {/* <SlidingTopBottom />
          <SlideUpAnimation /> */}
          <Serviecs Data={pageData.services} />
          <Cta Data={pageData.cta} />
          <Footer />
        </div>
      </SmoothScroll>
    </>
  );
};

export default Home;
