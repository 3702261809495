import * as React from "react";
const SVGComponent = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 415 115" {...props}>
    <g
      transform="matrix(0.19280900061130524, 0, 0, -0.24720901250839233, -53.67544937133789, 170.7439270019531)"
      fill="#fff"
      stroke="none"
      style={{}}
    >
      <path
        d="M460 631 c79 -16 78 -32 -18 -210 -37 -69 -68 -129 -70 -133 -2 -5&#10;23 -8 55 -7 53 1 55 2 26 10 -26 7 -33 15 -33 34 0 41 34 55 137 55 91 0 93 0&#10;107 -28 20 -39 7 -55 -51 -63 -37 -5 -24 -7 56 -8 l104 -1 -21 23 c-33 35&#10;-152 276 -152 308 l0 29 -87 -1 c-63 -1 -78 -3 -53 -8z m131 -122 c22 -45 38&#10;-87 35 -94 -4 -12 -153 -28 -165 -18 -3 2 12 38 31 81 20 42 39 85 42 95 3 9&#10;8 17 11 17 2 0 23 -36 46 -81z"
      />
      <path
        d="M807 603 c11 -54 8 -263 -5 -295 l-12 -28 68 1 c45 0 60 3 47 9 -40&#10;16 -45 34 -49 168 -5 155 -2 163 75 173 47 5 42 6 -39 8 l-93 1 8 -37z"
      />
      <path
        d="M1028 628 c12 -6 22 -15 22 -19 0 -12 -38 -46 -90 -81 -66 -45 -79&#10;-58 -74 -73 3 -8 39 -41 80 -75 41 -34 73 -67 71 -74 -2 -6 -23 -14 -48 -17&#10;-24 -3 30 -6 121 -7 120 0 156 2 132 9 -28 8 -33 14 -30 37 4 39 39 52 140 52&#10;93 0 108 -8 108 -59 0 -21 -12 -26 -75 -34 -16 -2 17 -5 74 -6 l104 -1 -21 23&#10;c-33 35 -152 276 -152 308 l0 29 -87 -1 c-63 -1 -78 -3 -53 -8 78 -16 78 -27&#10;-6 -189 -85 -163 -84 -162 -129 -130 -110 78 -186 144 -183 160 2 9 50 51 107&#10;92 l104 76 -69 -1 c-57 0 -65 -2 -46 -11z m353 -119 c22 -45 38 -87 35 -94 -4&#10;-12 -153 -28 -165 -18 -3 2 12 38 31 81 20 42 39 85 42 95 3 9 8 17 11 17 2 0&#10;23 -36 46 -81z"
      />
      <path
        d="M1653 629 c-18 -5 -39 -19 -48 -31 -40 -58 0 -108 122 -151 130 -46&#10;162 -79 119 -122 -20 -20 -35 -25 -77 -25 -94 0 -148 45 -135 112 7 37 4 33&#10;-43 -45 l-23 -37 44 -19 c130 -58 288 -28 288 54 0 26 -41 77 -70 87 -11 3 -7&#10;17 19 63 l33 59 -26 23 c-46 39 -131 53 -203 32z m143 -33 c21 -18 28 -32 28&#10;-62 0 -74 -4 -77 -79 -50 -77 28 -105 50 -105 82 0 54 105 74 156 30z"
      />
      <path
        d="M1978 608 c9 -42 9 -241 0 -291 l-7 -37 67 1 c37 1 56 4 42 6 -35 6&#10;-50 30 -50 80 0 71 20 83 141 83 l102 0 -6 -61 c-6 -72 -20 -90 -79 -100 -33&#10;-5 -22 -7 51 -8 85 -1 93 1 86 17 -12 30 -17 224 -7 285 l9 57 -56 0 c-47 -1&#10;-53 -2 -36 -12 11 -6 23 -16 27 -22 13 -18 9 -113 -4 -124 -7 -5 -60 -12 -118&#10;-14 l-105 -3 -3 69 c-2 40 2 74 8 80 6 6 34 13 63 17 44 5 38 6 -40 8 l-92 1&#10;7 -32z"
      />
    </g>
  </svg>
);
export default SVGComponent;
