import React, { useState, useEffect } from "react";
import WebLayout from "../WebLayout";
import Heading from "../Heading";

const Index = ({ Data }) => {
  const renderExperience = () => {
    return Data.experienceData.map((item, index) => (
      <React.Fragment key={index}>
        <div className="flex items-start justify-between w-full pt-9 pb-4 h-auto lg:flex-row flex-col  overflow-visible p-0 relative box-border">
          <div className="flex flex-col items-start justify-start opacity-100 flex-shrink-0 transform-none relative box-border">
            <p className="text-white text-left font-normal text-[20px] leading-[28.8px] m-0 p-0 box-border antialiased">
              {item.role}
            </p>
          </div>
          <div className="flex flex-col items-start justify-start opacity-100 flex-shrink-0 transform-none relative box-border">
            <p className="text-[rgba(255,255,255,0.8)] text-left font-normal text-[18px] leading-[28.8px] m-0 p-0 box-border antialiased">
              {item.duration}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start w-full float-right opacity-100 flex-shrink-0 transform-none relative box-border">
          <p className="text-[rgba(255,255,255,0.7)] text-left font-normal text-[18px] leading-[28.8px] m-0 p-0 box-border antialiased">
            {item.description}
          </p>
          <ul className="list-disc list-inside pl-4 mt-2 text-[rgba(255,255,255,0.6)]">
            {item.responsibilities.map((responsibility, i) => (
              <li key={i} className="text-[16px] leading-[24px] mb-1">
                {responsibility}
              </li>
            ))}
          </ul>
        </div>
      </React.Fragment>
    ));
  };

  return (
    <WebLayout>
      <Heading title={Data.title} />
      <div className="flex flex-col w-[85%] items-end justify-start  mt-[60px] overflow-hidden p-0 mx-auto relative box-border">
        {renderExperience()}
      </div>
    </WebLayout>
  );
};

export default Index;
